import React, { Component } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
//import VennDiagramSection from "../components/story/vennDiagramSection"
// import VennFigureSection from "../components/story/venn-figure-section";
// import AnimatedLinesSection from "../components/story/animated-lines-section";
// import ImagesSection from "../components/story/images-section";
// import BenefitsSection from "../components/story/benefits-section";
// import BestSmileSection from "../components/story/best-smile-section";
// import GummiesTincturesSection from "../components/story/gummies-tinctures-section";

class StoryPage extends Component {
  render () {
    return (
      <Layout isAlternate={true}>
        <SEO title="Our Story" />
        {/*<VennDiagramSection/>*/}
        {/*<VennFigureSection />*/}
        {/*<div className="is-overflow-hidden">*/}
        {/*  <AnimatedLinesSection />*/}
        {/*  <ImagesSection />*/}
        {/*</div>*/}
        {/*<BenefitsSection />*/}
        {/*<BestSmileSection />*/}
        {/*<GummiesTincturesSection />*/}
      </Layout>
    );
  }
}

export default StoryPage;
