/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";

import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./header";
import Footer from "./footer";
import GlobalHelmet from "./global-helmet";
import GlobalModals from "./global-modals";

const Layout = ({ isAlternate, children }) => {
  const data = useStaticQuery(graphql`
      query SiteTitleQuery {
          site {
              siteMetadata {
                  title
              }
          }
      }
  `);

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} isAlternate={isAlternate} />
      <div id="main-wrapper">
        <main id="main">{children}</main>
      </div>
      <GlobalModals />
      <Footer />
      <GlobalHelmet />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
